import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import { useState, useEffect } from "react";
import * as React from "react";
import {
  Table,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { Admin, Resource } from "react-admin";
import { UserList } from "./UserList";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { writeFile, utils } from "xlsx";
let conversions = {
  name: "નામ",
  relation: "વડા સાથેનો સંબંધ",
  gender: "લિંગ",
  age: "ઉંમર(પૂર્ણ કરેલવર્ષ)",
  educurrent: "શિક્ષણ(અભ્યાસ ચાલુ)",
  educfinish: "શિક્ષણ(પૂર્ણ)",
  marstatus: "વૈવાહિક દરજ્જો",
  mainbusiness: "વ્યવસાય(મુખ્ય)",
  sidebusiness: "વ્યવસાય(ગૌણ)",
  avgyincom: "સરેરાશ માસિક આવક",
  phymendeffct: "શારિ/માન. ખોડખાપણ",
  cvaccine: "કોરોનાની વેક્સીન લીધેલ છે. હા/ના",
};
const convertKey = (key) => conversions[key];
const Dashboard = (props) => {





  const navigate = useNavigate();
  let keysss = Object.keys(conversions);
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);



  const createFamilyObj = (familyData, keys) => {
    // Create a new object for each family member using the specified keys
    return familyData.map((familyMember) => {
      return keys.reduce((acc, key) => {
        acc[convertKey(key)] = familyMember[key];
        return acc;
      }, {});
    });
  };

  const processNewData = (newData) => {
    // Extract the answers and keys from each item in newData
    const finalArray = newData.map((item, idx) => {
      const answersObj = JSON.parse(item.answers);
      const answersKey = Object.keys(answersObj);
      const replacedSymbolsKeys = answersKey.map((key) => key);

      // Set the column headers for the table based on the first item in newData
      if (idx === 0) {
        const familyColumns = keysss.map((i) => conversions[i]);
        // setTableHeaders([...columns, ...familyColumns]);
        // setTableHeaders([...replacedSymbolsKeys, ...familyColumns]);
      }

      // Create the initial object with answers for the table row
      let finalObj = {};
      replacedSymbolsKeys.forEach((key) => {
        finalObj[key] = answersObj[key];
      });

      // Add family data to finalArray
      const familyData = JSON.parse(item.famalies);
      const familyKeys = [
        "name",
        "avgyincom",
        "cvaccine",
        "educfinish",
        "educurrent",
        "gender",
        "mainbusiness",
        "marstatus",
        "phymendeffct",
        "relation",
        "sidebusiness",
        "age",
      ];
      const familyObjs = createFamilyObj(familyData, familyKeys);
      return [...familyObjs.map((obj) => ({ ...finalObj, ...obj }))];
    });

    // Combine all of the objects in finalArray into a single array
    const flattenedFinalArray = finalArray.flat();
    setTableData(flattenedFinalArray);
  };


  const fetchPost = async () => {
    await getDocs(collection(db, "data2")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      let columns = [];
      let finalArray = [];
      console.log("doc-------------------------- ", newData)

      processNewData(newData)
      // newData.map((i, idx) => {
      //   let finalObj = {};
      //   let finalObjTmp = {};
      //   let answersObj = JSON.parse(i.answers);
      //   let answersKey = Object.keys(answersObj);
      //   let replacedSymbolsKeys = answersKey.map(
      //     (j) => j
      //     // .replaceAll(":", "").replaceAll('"', "")
      //   );
      //   if (idx === newData.length - 1) {
      //     columns = replacedSymbolsKeys;
      //   }
      //   replacedSymbolsKeys.map((j) => {
      //     finalObj[j] =
      //       answersObj[
      //       j
      //       // .replaceAll(":", "").replaceAll('"', "")
      //       ];
      //     finalObjTmp[j] = "";
      //   });
      //   let familyData = JSON.parse(i.famalies);
      //   for (let a = 0; a < familyData.length; a++) {
      //     let familyObj = {
      //       ...finalObj,
      //       [convertKey("name")]: familyData[a]["name"],
      //       [convertKey("avgyincom")]: familyData[a]["avgyincom"],
      //       [convertKey("cvaccine")]: familyData[a]["cvaccine"],
      //       [convertKey("educfinish")]: familyData[a]["educfinish"],
      //       [convertKey("educurrent")]: familyData[a]["educurrent"],
      //       [convertKey("gender")]: familyData[a]["gender"],
      //       [convertKey("mainbusiness")]: familyData[a]["mainbusiness"],
      //       [convertKey("marstatus")]: familyData[a]["marstatus"],
      //       [convertKey("phymendeffct")]: familyData[a]["phymendeffct"],
      //       [convertKey("relation")]: familyData[a]["relation"],
      //       [convertKey("sidebusiness")]: familyData[a]["sidebusiness"],
      //       [convertKey("age")]: familyData[a]["age"],
      //     };
      //     finalArray.push(familyObj);
      //     finalObj = finalObjTmp;
      //   }
      // });
      // const familyColumns = keysss.map((i) => conversions[i]);
      // setTableHeaders([...columns, ...familyColumns]);
      // setTableData(finalArray);
    });
  };
  useEffect(() => {
    if (
      localStorage.getItem("loggedIn") &&
      localStorage.getItem("token") === "WQIiVDBBP2inJhOT91ra"
    ) {
      fetchPost();
    } else {
      navigate("/");
    }
  }, []);
  const downloadXLSX = () => {
    var ws = utils.json_to_sheet(tableData, { header: tableHeaders });
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Survey");
    writeFile(wb, "Survey.xlsx");
  };
  const data = tableData;
  const headers = tableHeaders.map((i) => ({ label: i, key: i }));
  return (
    <div>
      <div
        style={{
          textAlign: "right",
          margin: "5px 10px",
        }}
      >
        {tableData.length ? (
          <IconButton onClick={downloadXLSX}>
            <Typography
              style={{
                marginRight: 5,
              }}
            >
              Download XLSX
            </Typography>
            <DownloadIcon />
          </IconButton>
        ) : (
          <></>
        )}
        {tableData.length ? (
          <CSVLink
            data={data}
            headers={headers}
            filename="Survey"
            style={{
              textDecoration: "none",
            }}
          >
            <IconButton>
              <Typography
                style={{
                  marginRight: 5,
                }}
              >
                Download CSV
              </Typography>
              <DownloadIcon />
            </IconButton>
          </CSVLink>
        ) : (
          <></>
        )}
        <IconButton
          onClick={() => {
            localStorage.clear();
            navigate("/");
          }}
        >
          <Typography
            style={{
              marginRight: 5,
            }}
          >
            Logout
          </Typography>
          <LogoutIcon />
        </IconButton>
      </div>
      <div
        className="todo-content"
        style={{
          margin: 20,
          boxShadow:
            "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 10px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
          overflow: "auto",
        }}
      >
        <TableContainer
          component={Paper}
          style={{
            // backgroundColor: "#D6EEEE",
            overflowX: "initial",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                {tableHeaders.map((i, idx) => (
                  <TableCell key={idx}>{i}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                backgroundColor: "#D6EEEE",
              }}
            >
              {/* {tableData.map((row, idxe) => (
                <TableRow
                  key={idxe}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {tableHeaders.map((i, idx) => (
                    <TableCell key={idx}>{row[i]}</TableCell>
                  ))}
                </TableRow>
              ))} */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Dashboard;
